import React, { useEffect, useState } from "react";
import bgbanner from "../assets/bg-banner.jpg";
import { useLocation } from "react-router-dom";
import { backend_url } from "../merc/config";
import axios from "axios";
import Loader from "./loader";
import { request } from "./common-tool";
import Toaster from "./toaster";
import { useWeb3React } from "@web3-react/core";
import { personal_sign } from "../common/metamask";
import CompositeRecordModal from "./compositeRecordActionModal";
import { width } from "../merc/style/button_style";
import AccordionData from "./accordionData";
import { downloadJson, waasAccount } from "../service/functionsList";
import CompositeListTab from "./CompositeListTab";

const Lodgeit = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [result, setResult] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState({
    data: {},
    index: null,
    key: null,
  });
  const location = useLocation();
  const [showToast, setShowToast] = useState(false);
  const queryParams = new URLSearchParams(location.search);
  const abn = queryParams.get("abn");
  const source = queryParams.get("source");
  const [headers, setHeaders] = useState("");
  const [text, setText] = useState("");
  const context = useWeb3React();
  const [preview, setPreview] = useState(true);
  const { account } = context;
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [compositeLists, setCompositeLists] = useState();
  useEffect(() => {
    fetchCompositeRecords();
  }, [abn]);
  const fetchCompositeRecords = () => {
    setIsLoading(true);
    axios
      .post(backend_url + "composite-one-record", { abn: abn, source: source })
      .then((res) => {

        console.log(res, "RES")
        setShowToast(true);
        res?.status == 200 && setResult(res?.data?.result) ;
        res?.status == 200 && setCompositeLists(res?.data?.databases);

        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setShowToast(true);
        setIsLoading(false);
      });
  };

  const handleMint = async (isWaas) => {
    if (isWaas) {
      try {
        var params = {};
        var reason = `CompositeRecordQuery=${abn}`;
        params.query = "Composite Record mint";
        params.address = waasAccount;
        params.type = "spend";
        let fee = 50;

        if (fee > 0) {
          var seed = await new Promise((resolve) =>
            request(resolve, params.address, fee, reason)
          );

          if (seed === "") {
            return;
          } else {
            // let vk = await personal_sign(seed, context.account);
            params.verification = null;

            params.seed = seed;
            // params.isWass = true
            axios
              .get("https://backend.bsearchau.accziom.com:8885/verify", {
                method: "get",
                headers: {
                  "Content-Type": "application/json",
                },
                params,
              })
              .then((response) => {
                if (response?.error || response?.data?.error) {
                  console.log(response.data.error);
                } else {
                  const res = axios
                    .post(backend_url + "mint-nft-composite", { result })
                    .then((res) => {
                      alert(res?.data?.message);
                    })
                    .catch((err) => {
                      console.log(err);
                      console.log(
                        err?.response?.message || "error fetching response"
                      );
                    });
                }
              })
              .catch((err) => {
                console.log(err);
                alert(
                  err?.response?.data?.message ||
                    "Error in paying amount. Please try again later"
                );
              });
          }
        }
      } catch (err) {
        console.log(JSON.stringify(err));
        alert("Failed to mint.");
      }
    } else {
      if (account) {
        try {
          var params = {};
          var reason = `CompositeRecordQuery=${abn}`;
          params.query = "Composite Record mint";
          params.address = `${context.account}`;
          let fee = 50;

          if (fee > 0) {
            var seed = await new Promise((resolve) =>
              request(resolve, params.address, fee, reason)
            );

            if (seed === "") {
              return;
            } else {
              let vk = await personal_sign(seed, context.account);
              params.verification = vk;
              params.seed = seed;
              if (vk) {
                axios
                  .post(backend_url + "verify", params)
                  .then((response) => {
                    const res = axios
                      .post(backend_url + "mint-nft-composite", { result })
                      .then((res) => {
                        alert(res?.data?.message);
                      })
                      .catch((err) => {
                        console.log(err);
                        console.log(
                          err?.response?.message || "error fetching response"
                        );
                      });
                  })
                  .catch((err) => {
                    alert(
                      err?.response?.data?.message ||
                        "Error in paying amount. Please try again later"
                    );
                  });
              }
            }
          }
        } catch (err) {
          console.log(JSON.stringify(err));
          alert("Failed to mint.");
        } finally {
        }
      } else {
        alert("Please login to use mint function");
      }
    }
  };

  const actionFunction = async (element, index, key, type) => {
    if (type == "DELETE") {
      setPreview(false);
      result[key][index] && result[key].splice(index, 1);
      setTimeout(() => {
        setPreview(true);
      }, 10);
      setTimeout(() => {
        alert("Entry deleted successfully.");
      }, 20);
    } else if (type == "ADD") {
      setShowModal(true);
      setHeaders("Add new entry");
      let obj = {};
      Object.keys(result[key][index]).map((element, indexss) => {
        if (element !== "action") {
          obj[element] = "";
        } else {
          obj[element] = result[key][index][element];
        }
      });
      console.log(obj);
      setSelectedRow({
        data: obj,
        index: null,
        key: key,
      });
    } else {
      setHeaders("Edit entry Modal");
      setSelectedRow({
        data: result[key][index],
        index: index,
        key: key,
      });
      setTimeout(() => {
        setShowModal(true);
      }, 100);
    }
    // console.log(result)
  };

  const bingQueryHandler = async () => {
    setIsLoading(true);
    axios
      .post(backend_url + "bing_query_new", result)
      .then((res) => {
        setIsLoading(false);
        setResult(res?.data);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  return (
    <>
      <Toaster />
      {showModal && (
        <CompositeRecordModal
          setPreview={setPreview}
          result={result}
          setResult={setResult}
          setShowModal={setShowModal}
          data={selectedRow}
          headerText={headers}
        />
      )}
      {isLoading && <Loader />}
      <section className="bsearch1-wrapper">
        <div className="position-relative banner-wrapper mb-3">
          <img src={bgbanner} alt="banner" className="img-fluid" />
          <div className="position-absolute banner-content">
            <h3 className="fs-4">IS YOUR DATABASE REGISTERED?</h3>
            <p>Check if your database is registered with us</p>
          </div>
        </div>
        <div className="container">
          <div className="row mb-3">
            <div className="col-12">
              <h5>Legal Entitiy Profile</h5>
              <p>
                This pane displays the information of a legal entity derived
                from Australian Legal Entity Knowlege base. It has two versions:
                Free and Standard version. In the Free version, only the data
                from public resources (e.g. ABR) is available. In the Standard
                version, all the data built up by the Accziom community is
                available in the display or can be called via our API.
              </p>
            </div>
            <div className="d-flex justify-content-end mt-2">
              {/* <button
                className="btn btn-primary btn-sm px-3  ms-2"
                onClick={bingQueryHandler}
              >
                Add Bing query
              </button> */}
              <button
                className="btn btn-primary btn-sm px-3  ms-2"
                onClick={() => {
                  handleMint(true);
                }}
              >
                Mint Record using WAAS (50 merc)
              </button>
              <button
                className="btn btn-primary btn-sm px-3  ms-2"
                onClick={() => {
                  handleMint(false);
                }}
              >
                Mint Record using Local wallets (50 merc)
              </button>
              <button
                className="btn btn-primary btn-sm px-3  ms-2"
                onClick={() => {
                  downloadJson(result);
                }}
              >
                Download JSON-LD
              </button>
            </div>
          </div>

          {/* Tab section */}

          <section>
            <div className="tab-section">
              <nav>
                <div class="nav nav-tabs" id="nav-tab" role="tablist">
                  <button
                    class="nav-link active"
                    id="nav-home-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-home"
                    type="button"
                    role="tab"
                    aria-controls="nav-home"
                    aria-selected="true"
                  >
                    Tab 1
                  </button>
                  <button
                    class="nav-link"
                    id="nav-profile-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-profile"
                    type="button"
                    role="tab"
                    aria-controls="nav-profile"
                    aria-selected="false"
                  >
                    Tab 2
                  </button>
                </div>
              </nav>
              <div class="tab-content" id="nav-tabContent">
                <div
                  class="tab-pane fade show active"
                  id="nav-home"
                  role="tabpanel"
                  aria-labelledby="nav-home-tab"
                >
                  <div className="row">
                    <div className="col-12">
                      <div className="card">
                        <AccordionData
                          preview={preview}
                          result={result}
                          setSelectedIndex={setSelectedIndex}
                          actionFunction={actionFunction}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="tab-pane fade"
                  id="nav-profile"
                  role="tabpanel"
                  aria-labelledby="nav-profile-tab"
                >
                  <CompositeListTab compositeLists={compositeLists} setCompositeLists={setCompositeLists} result={result} setResult = {setResult} setIsLoading = {setIsLoading}/>
                </div>
              </div>
            </div>
          </section>
        </div>

        <div
          class="modal fade"
          id="exampleModalToggle2"
          aria-hidden="true"
          aria-labelledby="exampleModalToggleLabel2"
          tabindex="-1"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h1 class="modal-title fs-5" id="exampleModalToggleLabel2">
                  Modal 2
                </h1>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                <div class="table-responsive">
                  <table class="table table-striped table-hover text-center align-middle">
                    <thead class="table-primary">
                      <tr>
                        <th scope="col">Name</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="table">
                        <td>LINK Advisors</td>
                      </tr>
                      <tr class="table">
                        <td>Perigee Advisers Pty Ltd</td>
                      </tr>
                      <tr class="table">
                        <td>Knight BPO</td>
                      </tr>
                      <tr class="table">
                        <td>Target Accounting Service</td>
                      </tr>
                      <tr class="table">
                        <td>Rasmussen Chartered Accountants</td>
                      </tr>
                      <tr class="table">
                        <td>Wardle Partners Accountants &amp; Advisors</td>
                      </tr>
                      <tr class="table">
                        <td>Catalyst Plus Pty Limited</td>
                      </tr>
                      <tr class="table">
                        <td>The Small Business Lounge</td>
                      </tr>
                      <tr class="table">
                        <td>Accounting For Small Business Pty Ltd</td>
                      </tr>
                      <tr class="table">
                        <td>5ways Group</td>
                      </tr>
                      <tr></tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="modal-footer">
                <button class="btn btn-primary edit-btn">Close</button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Lodgeit;
