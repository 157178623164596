export default function AccordionData(props) {
    const { preview, result, setSelectedIndex, actionFunction } = props;

    const toolTipsText = {
        "Legal Name": "The official registered name of a business or entity as listed in legal documents and with government agencies.",
        "Entity Name": "The registered name of a company or organization as it appears on the Australian Business Register (ABR).",
        "Business Name": "A name under which a business operates in Australia, registered with ASIC (Australian Securities and Investments Commission).",
        "Trading Name": "The name a business uses in the market, which may differ from its legal or business name.",
        "Name": "The general designation used to identify a person, business, or entity.",
        "ABR Entity Type": "The classification of a business entity according to the Australian Business Register (e.g., sole trader, company, trust).",
        "ABN": "A unique 11-digit number assigned to businesses by the Australian Business Register for taxation and government dealings.",
        "Postal Code": "A numerical code used to identify a specific geographic area for mail delivery within Australia.",
        "State": "A primary political division of Australia, such as New South Wales, Victoria, or Queensland.",
        "Suburb": "A residential area or district within a city or town in Australia.",
        "Active Status": "Indicates whether a business is currently active or operational as per the Australian Business Register.",
        "GST Effective Date": "The date from which a business is registered for the Goods and Services Tax (GST) in Australia.",
        "ABR Last Confirmed Date": "The last date when the business details were confirmed or updated with the Australian Business Register.",
        "ACN": "A unique nine-digit number issued by ASIC to every company registered in Australia, used to identify the company for legal and regulatory purposes.",
    };

    const displayKeyName = (key) => {
        const keyMapping = {
            "Postal code": "Postal Code",
        };
        return keyMapping[key] || key;
    };

    return (
        <div className="card-body">
    {preview && (
        <div className="accordion" id="accordionExample">
            {Object.keys(result)?.filter(key => 
                result[key] && 
                Array.isArray(result[key]) && 
                result[key].length > 0 && 
                result[key] !== -1
            ).map((key, index) => (
                <div className="accordion-item" key={index}>
                    <h2 className="accordion-header">
                        <button
                            className="accordion-button"
                            type="button"
                            onClick={() => setSelectedIndex(index)}
                            data-bs-toggle="collapse"
                            data-bs-target={`#collapseOne${index}`}
                            aria-expanded={true}
                            aria-controls={`collapseOne${index}`}
                        >
                            {displayKeyName(key)}
                            <button
                                type="button"
                                className="btn btn-transparent p-0 ms-2"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title={toolTipsText?.[key] || "Not available"}
                            >
                                <i className="bi bi-info-circle-fill text-primary"></i>
                            </button>
                        </button>
                    </h2>
                    <div
                        id={`collapseOne${index}`}
                        className="accordion-collapse collapse show"
                    >
                        <div className="accordion-body">
                            <div className="table-responsive">
                                <table className="table table-hover text-center align-middle">
                                    <thead className="table-primary">
                                        {result[key][0] && (
                                            <tr>
                                                {Object.keys(result[key][0])
                                                    .filter(keyss => keyss !== "action")
                                                    .map((keyss, index) => (
                                                        <th key={index}>{keyss}</th>
                                                    ))
                                                }
                                            </tr>
                                        )}
                                    </thead>
                                    <tbody>
                                        {result[key].map((element, indexs) => (
                                            <tr
                                                key={indexs}
                                                className={`${
                                                    element?.score === "High"
                                                        ? "table-rows-success"
                                                        : element?.score === "Medium"
                                                        ? "table-rows-warning"
                                                        : "table-rows-danger"
                                                }`}
                                            >
                                                {Object.keys(element)
                                                    .filter(keyz => keyz !== "action")
                                                    .map((keyz, index) => {
                                                        if (key === "Url" && keyz === "value") {
                                                            return (
                                                                <td key={index}>
                                                                    {element[keyz].map((entry, i) => (
                                                                        < >
                                                                            <a 
                                                                                target="_blank"
                                                                                href={entry}
                                                                                rel="noopener noreferrer"
                                                                            >
                                                                                {entry}
                                                                            </a>
                                                                            <br />
                                                                        </>
                                                                    ))}
                                                                </td>
                                                            );
                                                        }
                                                        return (
                                                            <td
                                                                key={index}
                                                                dangerouslySetInnerHTML={{
                                                                    __html: element[keyz],
                                                                }}
                                                            />
                                                        );
                                                    })}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    )}
</div>
    );
}
