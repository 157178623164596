import { useEffect, useCallback, useState } from "react";
import { Modal, Box, Button, TableRow, TableCell, TableHead, TableBody, Table, Typography, TextField, IconButton } from "@mui/material";
import { AddCircle } from "@mui/icons-material"; // Import an icon

import axios from "axios";

const CompositeListTab = (props) => {
  const [openModal, setOpenModal] = useState(false);
  const [names, setNames] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [database, setDatabase] = useState("")

  const databases = {
    "ABR Data": "abrData",
    "ACN Data": "acnData",
    "TPB Data": "tpbData",
    "Quickbook Data": "quickbookData",
    "Xero Data": "xeroData",
    "LEI Data": "leiData",
    "CA Data": "caData",
    "PA Data": "paData",
    "ASX Data": "asxData"
  }


  const queries = {
    "ABR Data": "abn_query",
    "ASX Data": "asx_query",
    "Quickbook Data": "quickbook_query",
    "TPB Data": "tpb_query",
    "CA Data": "ca_query",
    "PA Data": "pa_query",
    "Xero Data": "xero_query",
    "ACN Data": "acn_query",
    "LEI Data": "lei_query",
  };
  const searchQueries = {
    "ABR Data": "query",
    "ASX Data": "name",
    "Quickbook Data": "company_name",
    "TPB Data": "legal_name",
    "CA Data": "name",
    "PA Data": "name",
    "Xero Data": "name",
    "ACN Data": "query",
    "LEI Data": "name",
  }

  const handleOpenModal = (title) => {
    setSearchQuery("")
    setOpenModal(true);
    setDatabase(title)
    fetchDbNames(title);
  };

  const fetchDbNames = (title, query = "") => {
    setIsSearching(true);

    const searchKey = searchQueries[title]; // Retrieve the key from searchQueries for the given title
    const value = query === "" ? undefined : query; // Set value to undefined if query is empty

    axios
      .post(
        `https://backend.bsearchau.accziom.com:8002/${queries[title]}`,
        { [searchKey]: value }, // Use the key from searchQueries dynamically
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        setNames(res?.data?.data || []);
        setIsSearching(false);
      })
      .catch(() => {
        setIsSearching(false);
      });
  };

  const addName = () => {


    // console.log(props)
    handleCloseModal()
    props.setIsLoading(true)
    axios.post('https://backend.bsearchau.accziom.com:8002/regenerate-composite-record',
      { result: props.result, databases: props.compositeLists }
    ).then((res) => {
      // console.log(res)
      props.setIsLoading(false)
      props.setResult(res?.data?.result)
      props.setCompositeLists(res?.data?.databases)
    }).catch((err) => {
      console.log(err)
    })
  }



  const handleCloseModal = () => setOpenModal(false);

  const renderEntries = (entries) => {
    if (Array.isArray(entries)) {
      return entries.map((entry, index) => {
       
        if (typeof entry === "object" && entry !== null) {
          return renderObject(entry, index);
        }

        if (Array.isArray(entry)) {
          return (
            <ul key={index}>
              {entry.map((subItem, subIndex) => {
                return
                (
                <li key={subIndex}>{renderEntries(subItem)}</li>
              )})}
            </ul>
          );
        }

        return (
          <div key={index}>
            <h3>{entry}</h3>
          </div>
        );
      });
    }

    if (typeof entries === "object" && entries !== null) {
      return renderObject(entries);
    }

    return null;
  };

  const renderObject = (obj, keyPrefix = "") => {
    return Object.entries(obj).map(([key, value]) => {
      const fullKey = keyPrefix ? `${keyPrefix}.${key}` : key;

      if (typeof value === "object" && value !== null) {
        return (
          <div key={fullKey}>
            <span>{key}: </span>
            {renderObject(value, fullKey)}
          </div>
        );
      }

      return (
        <div key={fullKey}>
          <span>{key}: </span>
          <h3>{value}</h3>
        </div>
      );
    });
  };

  const renderAllData = useCallback(() => {
    const sections = [
      { title: "ABR Data", entries: props?.compositeLists?.abrData },
      { title: "ACN Data", entries: props?.compositeLists?.acnData },
      { title: "TPB Data", entries: props?.compositeLists?.tpbData },
      { title: "Quickbook Data", entries: props?.compositeLists?.quickbookData },
      { title: "Xero Data", entries: props?.compositeLists?.xeroData },
      { title: "LEI Data", entries: props?.compositeLists?.leiData },
      { title: "CA Data", entries: props?.compositeLists?.caData },
      { title: "PA Data", entries: props?.compositeLists?.paData },
      { title: "ASX Data", entries: props?.compositeLists?.asxData },
    ];

    return sections.map(({ title, entries }) => {
      if (
        !entries ||
        (Array.isArray(entries) && entries.length === 0) ||
        (Array.isArray(entries) && entries[0] == null && (title !==  "TPB Data")) ||
        (title ==  "TPB Data" && entries[1] == null) ||
        Object.keys(entries).length === 0 
      ) {
        return (
          // <div
          //   className="box-sec d-flex flex-wrap mb-5 col-12 col-md-4"
          //   key={title}
          //   style={{ height: "250px" }}
          // >
          //   <div className="boxgray min-height" style={{ height: "100%" }}>
          //     <div className="d-colum justify-content-center no-rec">
          //       <h3>NO RECORD FOUND</h3>
          //       <button
          //         className="btn btn-primary btn-sm px-3"
          //         onClick={() => {
          //           handleOpenModal(title);
          //         }}
          //       >
          //         CREATE RECORD
          //       </button>
          //     </div>
          //   </div>
          //   <div className="bottom-footer4 d-flex justify-content-center">
          //     <h6>{title}</h6>
          //   </div>
          // </div>
          <div className="col-sm-6 col-md-4 cards-parent">
            <div className="card card-wrapper">
              <div className="card-body card-scroll card-no-rec">
                <h3>NO RECORD FOUND</h3>
                <button
                  className="btn btn-primary btn-sm px-3"
                  onClick={() => {
                    handleOpenModal(title);
                  }}
                >
                  CREATE RECORD
                </button>
              </div>
              <div className="card-footer card-bottom">
                <h6 className="mb-0">{title}</h6>
              </div>
            </div>
          </div>
        );
      }

      return (
        // <div className="box-sec d-flex flex-wrap mb-5 col-12 col-md-4" key={title}>
        //   <div className="boxblue min-height">
        //     <ul>{renderEntries(entries)}</ul>
        //   </div>
        //   <div className="bottom-footer4 d-flex justify-content-center">
        //     <h6>{title}</h6>
        //   </div>
        // </div>
        <div className="col-sm-6 col-md-4 cards-parent">
          <div className="card card-wrapper">
            <div className="card-body card-scroll">
              <ul className="ps-0">{renderEntries(entries)}</ul>
            </div>
            <div className="card-footer card-bottom">
              <h6 className="mb-0">{title}</h6>
            </div>
          </div>
        </div>
      );
    });
  }, [props.compositeLists]);


  const handleAdd = (item) => {

    props.compositeLists[databases[database]].push(item)

    addName()

  };


  // Debounced search functionality
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetchDbNames(database, searchQuery);

    }, 500); // Adjust debounce time (500ms)

    return () => clearTimeout(delayDebounceFn);
  }, [searchQuery]);

  return (
    <div>
      <div className="container">
        {/* <div className="row">
          <div className="col-12 mt-4">
            <h5>Data Audit</h5>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
              industry's standard dummy text ever since the 1500s...
            </p>
          </div>
        </div> */}

        <div className="row">{renderAllData()}</div>
      </div>

      <Modal open={openModal} onClose={handleCloseModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            maxHeight: "70vh",
            overflow: "auto",
          }}
        >
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Search names..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            sx={{ mb: 2 }}
          />
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    backgroundColor: "#1976d2",
                    color: "white",
                    fontWeight: "bold",
                  }}
                >
                  Display Name
                </TableCell>
                <TableCell
                  sx={{
                    backgroundColor: "#1976d2",
                    color: "white",
                    fontWeight: "bold",
                  }}
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isSearching ? (
                <TableRow>
                  <TableCell colSpan={2}>Loading...</TableCell>
                </TableRow>
              ) : (
                names?.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      {item?.displayName ||
                        item?.Company_Name ||
                        item?.Name ||
                        item?.["Company Name"] ||
                        item?.Entity?.LegalName?.name ||
                        item?.company_name ||
                        item?.["Legal name"] ||
                        item?.Persons[0]?.fa_name}
                    </TableCell>
                    <TableCell>
                      <IconButton
                        color="primary"
                        onClick={() => handleAdd(item)}
                      >
                        <AddCircle />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>

          </Table>

        </Box>
      </Modal>
    </div>
  );
};

export default CompositeListTab;
